<script lang="ts" setup>
import { onMounted } from 'vue'

const term = ref('')

const router = useRouter()
const inputSearchRef = ref()
onMounted(() => {
    inputSearchRef.value.focus()
})
const route = useRoute()

function search() {
    if (term.value) {
        router.push({
            path: '/insights',
            hash: '#insightSearchResult',
            query: {
                nameOrDescription: term.value,
                region: route.query.region
            }
        })
    }
}
</script>

<template>
    <div class="header__search">
        <button name="search" @click.stop="search">
            <svg
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.75 4.6875C8.74492 4.6875 4.6875 8.74492 4.6875 13.75C4.6875 18.7551 8.74492 22.8125 13.75 22.8125C16.2008 22.8125 18.4244 21.8396 20.0557 20.259C20.0837 20.2208 20.115 20.1842 20.1496 20.1496C20.1842 20.115 20.2208 20.0837 20.259 20.0557C21.8396 18.4244 22.8125 16.2008 22.8125 13.75C22.8125 8.74492 18.7551 4.6875 13.75 4.6875ZM22.1186 20.7928C23.7216 18.89 24.6875 16.4328 24.6875 13.75C24.6875 7.70938 19.7906 2.8125 13.75 2.8125C7.70938 2.8125 2.8125 7.70938 2.8125 13.75C2.8125 19.7906 7.70938 24.6875 13.75 24.6875C16.4328 24.6875 18.89 23.7216 20.7928 22.1186L25.5871 26.9129C25.9532 27.2791 26.5468 27.2791 26.9129 26.9129C27.2791 26.5468 27.2791 25.9532 26.9129 25.5871L22.1186 20.7928Z"
                    fill="#2F363D"
                />
            </svg>
        </button>

        <input
            ref="inputSearchRef"
            v-model="term"
            class="header__search-input"
            placeholder="Site search "
            autofocus
            @keydown.enter="search"
        />
        <span
            v-if="term"
            class="header__search-cancel"
            @click="term = ''"
            >X</span
        >
    </div>
</template>

<style lang="scss" scoped></style>
